import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { ApolloClient, ApolloLink, ApolloProvider, concat, InMemoryCache } from "@apollo/client";
import App from "./App";
import { getGQLUrl } from "./graphql/api";
const { createUploadLink } = require("apollo-upload-client");

const httpLink = new createUploadLink({
   uri: getGQLUrl(),
   credentials: "include",
});

const authMiddleware = new ApolloLink((operation, forward) => {
   return forward(operation);
});

const client = new ApolloClient({
   cache: new InMemoryCache(),
   link: concat(authMiddleware, httpLink),
   credentials: "include",
   headers: {
      "Accept-Encoding": "gzip"
   }
});

const container = document.getElementById("app-root")!;
const root = createRoot(container);
root.render(
   <React.StrictMode>
      <ApolloProvider client={client}>
         <App />
      </ApolloProvider>
   </React.StrictMode>
);
