import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SUBMIT_LOGOUT } from "../../graphql/queries";

const Logout = () => {
   const [submitLogout] = useMutation(SUBMIT_LOGOUT);
   const navigate = useNavigate();
   useEffect(() => {
      submitLogout({ variables: {} })
         .then((res) => {})
         .finally(() => {
            navigate("/login", { replace: true });
         });
   }, []);

   return null;
};

export default Logout;
