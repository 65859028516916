import { downloadFieldFile, isString } from "../../../helpers/utils";
import Download from "../../icons/Download";

const FileField = ({
   placeHolder,
   isMulti,
   formData,
   options,
   name,
   ...rest
}: {
   name: string;
   placeHolder?: string;
   options?: {
      required?: boolean;
   };
   isMulti?: boolean;
   readOnly?: boolean;
   onChange: any;
   formData?: Map<string, any>;
}) => {
   const value = formData?.get(name);

   return (
      <div className='file-with-info'>
         <input
            name={`${name}`}
            onChange={rest.onChange}
            required={options?.required && !value}
            type='file'
            className='file-input'
            id={`${name}`}
            disabled={rest.readOnly}
         />
         <label htmlFor={`${name}`}>
            {value ? (
               isString(value) ? (
                  <span>{value}</span>
               ) : (
                  Array.from(value).map((f: any) => <span>{f.name}</span>)
               )
            ) : (
               <span>Choose file ...</span>
            )}
         </label>
         {value && (
            <button onClick={(ev) => downloadFieldFile(ev, value)}>
               <i>
                  <Download />
               </i>
            </button>
         )}
      </div>
   );
};

export default FileField;
