import { useEffect, useState } from "react";
import Table from "../../components/tabular/Table";
import ManageSetup from "./ManageSetup";
import { FormField, SetupKind } from "../../helpers/types";
import { useLazyQuery } from "@apollo/client";
import { GET_SETUPS } from "../../graphql/queries";
import BulkSetups from "./BulkSetups";
import usePagination from "../../hooks/usePagination";

type SetupDataType = {
   name: SetupKind;
   singular: string;
   fields: FormField[];
};

function PageFilter({ handleFilter }: { handleFilter: Function }) {
   const [searchValue, setSearchValue] = useState("");

   const onSearch = (ev: any) => {
      setSearchValue(ev.target.value);
      handleFilter(ev.target.value);
   };

   const submitSearch = (ev: any) => {
      ev.preventDefault();
   };
   return (
      <form className='flex-1 ' onSubmit={submitSearch}>
         <input
            type='search'
            name='search'
            placeholder='Filter ...'
            className='px-3 py-1 rounded-lg w-full'
            onChange={onSearch}
            defaultValue={searchValue}
         />
      </form>
   );
}

const SETUP_DATA: SetupDataType[] = [
   {
      name: "transporters",
      singular: "transporter",
      fields: [
         { name: "name", label: "Name", options: { required: true } },
         { name: "email", label: "Email", options: { required: true } },
         { name: "phone", label: "Phone", options: { required: true } },
         { name: "alt_phone", label: "Alt. Phone" },
      ],
   },
   {
      name: "customers",
      singular: "customer",
      fields: [
         { name: "name", label: "Name", options: { required: true } },
         { name: "email", label: "Email", options: { required: true } },
         { name: "phone", label: "Phone", options: { required: true } },
         { name: "alt_phone", label: "Alt. Phone" },
      ],
   },
   {
      name: "sites",
      singular: "site",
      fields: [{ name: "name", label: "Name", cols: 2, options: { required: true } }],
   },
   {
      name: "drivers",
      singular: "driver",
      fields: [
         { name: "name", label: "Name", options: { required: true } },
         { name: "licence_no", label: "Licence No" },
         { name: "email", label: "Email", options: { required: true } },
         { name: "phone", label: "Phone", options: { required: true } },
         { name: "alt_phone", label: "Alt. Phone" },
      ],
   },
];

export default function SetupsPage() {
   const [kind, setKind] = useState<SetupKind>("transporters");
   const [openNew, setOpenNew] = useState<boolean>(false);
   const [openBulk, setOpenBulk] = useState<boolean>(false);
   const [data, setData] = useState<any[]>([]);
   const [totalRecords, setTotalRecords] = useState(1);
   const { Pagination, pageInfo, setPageInfo } = usePagination(10);
   const setup = SETUP_DATA.find((s) => s.name === kind);
   const [getSetups, { loading }] = useLazyQuery(GET_SETUPS, {
      fetchPolicy: "network-only",
   });

   console.log({pageInfo})

   const [filter, setFilter] = useState("");
   const handleFilter = (value: string) => {
      console.log({ value });
      setFilter(value);
   };

   function refresh(page: number = 1) {
      getSetups({
         variables: {
            names: [kind],
            page: page,
            limit: pageInfo.limit,
            filter: filter,
         },
      }).then((res) => {
         if (res.data) {
            let setups = res.data.getSetups;
            setTotalRecords((prev) => setups.totalRecords);
            let sData = setups.records.map((s: any) => {
               let fields = s.fields.reduce(function (map: any, f: any) {
                  map[f.name] = f.value;
                  return map;
               }, {});

               return {
                  id: s.id,
                  ...fields,
               };
            });
            setData(sData);
         }
      });
   }

   useEffect(() => {
      setPageInfo((prev) => ({ ...prev, page: 1 }));
   }, [kind, pageInfo.limit]);

   useEffect(() => {
      refresh(pageInfo.page);
   }, [pageInfo, filter]);

   if (!setup) return null;
   const columns = [{ name: "id", label: "#" }, ...setup.fields];

   return (
      <div>
         <div className='toolbar flex justify-between px-2 py-2 items-center gap-10'>
            <h3 className='capitalize'>{`List of ${kind}`}</h3>
            <div className='flex gap-1 flex-1'>
               <input
                     type='search'
                     name='search'
                     placeholder='Filter ...'
                     className='flex-1 px-3 py-1 rounded-lg w-full'
                     onChange={(ev)=>handleFilter(ev.target.value)}
                     defaultValue={filter}
                  />
               <select
                  value={kind}
                  className='px-2 rounded capitalize border'
                  onChange={(ev) => setKind(ev.target.value as SetupKind)}
               >
                  {SETUP_DATA.map((s, idx) => (
                     <option key={idx} value={s.name}>
                        {s.name}
                     </option>
                  ))}
               </select>
               <button className='bg-primary px-2 py-1 rounded text-white' onClick={() => setOpenNew(true)}>
                  Add New
               </button>
               <button className='bg-green-400 px-2 py-1 rounded text-white' onClick={() => setOpenBulk(true)}>
                  Bulk Setups
               </button>
            </div>
         </div>
         <Table
            columns={[
               ...columns,
               {
                  name: "action",
                  render: ({ row }: any) => {
                     return <button>View</button>;
                  },
               },
            ]}
            data={data}
            loading={loading}
            components={{
               toolbar: () => <p></p>,
            }}
         />
         {openNew && (
            <ManageSetup
               name={setup.name}
               title={setup.singular}
               fields={setup.fields}
               onComplete={() => {
                  setOpenNew(false);
                  refresh(1);
               }}
            />
         )}
         {openBulk && (
            <BulkSetups
               name={setup.name}
               title={setup.singular}
               fields={[
                  {
                     name: "file",
                     type: "file",
                     label: "Excel File",
                     cols: 2,
                  },
               ]}
               onComplete={() => {
                  setOpenBulk(false);
                  refresh(1);
               }}
            />
         )}

         <Pagination totalRecords={totalRecords} />
      </div>
   );
}
