import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RecordsPage from "./pages/records/RecordsPage";
import LoginPage from "./pages/auth/LoginPage";
import MainLayout from "./pages/MainLayout";
import SetupsPage from "./pages/setups/SetupsPage";
import Logout from "./pages/auth/Logout";
import Dashboard from "./pages/dashboard/Dashboard";
import { useState } from "react";
import AppContext from "./context/AppContext";
import ReportsPage from "./pages/reports/ReportsPage";

const router = createBrowserRouter([
   {
      path: "/",
      element: <MainLayout />,
      children: [
         { element: <Dashboard />, index: true },
         { element: <RecordsPage />, path: "/records" },
         { element: <SetupsPage />, path: "/setups" },
         { element: <ReportsPage />, path: "/reports" },
      ],
   },
   { element: <Logout />, path: "logout" },
   { path: "/login", element: <LoginPage /> },
]);

function App() {
   const [auth, setAuth] = useState<number>(0);

   return (
      <AppContext.Provider value={{ auth: auth, changeAuth: (newVal) => setAuth(newVal) }}>
         <RouterProvider router={router} />
      </AppContext.Provider>
   );
}

export default App;
