import { FormEvent, useState } from "react";
import Modal from "../../components/modal/Modal";
import FormWrapper from "../../components/forms/FormWrapper";
import useMultistepForm from "../../hooks/useMultistepForm";
import { useMutation, useQuery } from "@apollo/client";
import { FieldData } from "../../hooks/useForm";
import { GET_CHKLIST_ITEMS, SUBMIT_INSPECTION_REQUEST } from "../../graphql/queries";
import { FieldType } from "../../helpers/types";
import FormInputField from "../../components/forms/inputs/FormInputField";
import CloseIcon from "../../components/icons/CloseIcon";
import { RecordFieldsConfig } from "./config";

function CreateNewRecordForm({ onComplete }: { onComplete: Function }) {
   const { data, loading, error } = useQuery(GET_CHKLIST_ITEMS, {
      fetchPolicy: "network-only",
   });
   const [formData, setFormData] = useState(new Map());
   const [submitInspectionReq, { loading: submitLoading }] = useMutation(SUBMIT_INSPECTION_REQUEST);
   function updateFormData({ name, value }: FieldData) {
      setFormData((prev) => {
         return new Map([...prev, [name, value]]);
      });
   }

   let cfg = RecordFieldsConfig(updateFormData, formData, false);

   const onSubmit = (ev: FormEvent) => {
      ev.preventDefault();
      console.log("onSubmit?")
      if (isLastStep) {
         const arr = Array.from(formData, function (entry) {
            return {
               name: entry[0],
               value: entry[1],
               type: Array.isArray(entry[1]) ? FieldType.MULTIPLE : FieldType.SINGLE,
            };
         });

         let items = arr.filter((f) => f.name.split("-").length === 2);
         let inspection: any = {};
         cfg.introFields.forEach((f) => {
            inspection[f.name] = formData.get(f.name);
         });
         cfg.finalFields.forEach((f) => {
            inspection[f.name] = formData.get(f.name);
         });

         const submitData = items.map((i) => {
            let parts = i.name.split("-");
            let iFiles = arr.filter((f) => `${f.name}`.endsWith(`${parts[1]}-image`)).map((f) => f.value);
            let rName = `item-${parts[1]}-reason`;
            let remarks = arr.find((f) => {
               return f.name === rName;
            })?.value;
            console.log({ remarks, iFiles });

            return {
               itemId: parseInt(parts[1]),
               optionValue: i.value,
               attachments: iFiles,
               remarks: remarks,
            };
         });
         console.log({ submitData });
         submitInspectionReq({
            variables: {
               data: {
                  items: submitData,
                  remarks: "General comment",
                  inspection,
               },
            },
         })
            .then((res) => {
               if (res.data) {
                  onComplete();
               } else {
                  console.error("Error: ", res.errors);
               }
            })
            .catch(console.error);
      } else {
         next();
      }
   };
   let steps: any[] = [
      {
         fields: cfg.introFields,
      },
   ];
   if (data && !loading) {
      let max = 5;
      let step: any;
      data.getAllItems.forEach((i: any, idx: number) => {
         if (idx === 0 || idx % max === 0) {
            if (step) {
               steps.push(step);
            }
            step = {};
         }
         step.fields = step.fields || [];
         step.fields.push({
            name: `item-${i.id}`,
            label: i.name,
            cols: 2,
            type: "radio",
            onChange: updateFormData,
            formData: formData,
            options: {
               required: true,
            },
            choices: [
               { label: "Compliant", value: "Compliant" },
               { label: "Partially Compliant", value: "Partial" },
               { label: "Flawed", value: "Flawed" },
            ],
            conditions: [
               {
                  allow: (value: any) => ["Flawed", "Partial"].includes(value),
                  present: (
                     <FormInputField
                        label='Reason'
                        name={`item-${i.id}-reason`}
                        onBlur={(e: any) => {
                           let { name, value } = e.target;
                           updateFormData({ name, value });
                        }}
                        formData={formData}
                        options={{
                           required: true,
                        }}
                     />
                  ),
               },
               {
                  allow: (value: any) => value === "Flawed",
                  present: (
                     <FormInputField
                        label='Provide image'
                        name={`item-${i.id}-image`}
                        type='file'
                        onChange={updateFormData}
                        formData={formData}
                        options={{
                           required: true,
                        }}
                     />
                  ),
               },
            ],
         });
      });
      if (step) {
         steps.push(step);
      }
   }
   steps.push({
      fields: cfg.finalFields,
   });
   const { numberOfSteps, currentStepIndex, step, next, back, isFirstStep, isLastStep } = useMultistepForm(
      steps.map((s, idx_) => (
         <FormWrapper key={idx_} fields={s.fields} updateFormData={updateFormData} formData={formData} />
      ))
   );

   if (loading)
      return (
         <Modal
            header={
               <div className='title flex justify-between items-center w-full'>
                  <h2>Capture New Checklist</h2>
                  <button onClick={() => onComplete()}>
                     <CloseIcon />
                  </button>
               </div>
            }
            handleClose={() => console.debug("Clicked...")}
            className='checklist-form'
         >
            <p>Loading setups...</p>
         </Modal>
      );
   if (error) return <p>Error...</p>;

   return (
      <Modal
         header={
            <div className='title flex justify-between items-center w-full'>
               <h2>Capture New Checklist</h2>
               <button onClick={() => onComplete()}>
                  <CloseIcon />
               </button>
            </div>
         }
         handleClose={() => console.debug("Clicked...")}
         className='checklist-form'
      >
         <form onSubmit={onSubmit} autoComplete='nope'>
            {step}
            {step && (
               <div className='flex justify-between items-center'>
                  <p>
                     {currentStepIndex + 1} / {numberOfSteps}
                  </p>
                  <div className='form-controls flex gap-3 justify-end py-2 text-lg'>
                     {!isFirstStep && (
                        <button className='border rounded px-5 py-1' onClick={back} type='button'>
                           Back
                        </button>
                     )}
                     {submitLoading ? (
                        <button type='button' className='border rounded px-5 py-1 bg-primary text-white btn-sm'>
                           Submiting ...
                        </button>
                     ) : (
                        <button type='submit' className='border rounded px-5 py-1 bg-primary text-white btn-sm'>
                           {isLastStep ? "Submit" : "Next"}
                        </button>
                     )}
                  </div>
               </div>
            )}
         </form>
      </Modal>
   );
}

export default CreateNewRecordForm;
