import { getDownloadUrl } from "../graphql/api";

export const isString = (value: any) => typeof value === "string";
export const downloadFieldFile = async (ev: any, value: any) => {
   ev.preventDefault();
   ev.stopPropagation();
   console.log({ value });
   let url = getDownloadUrl(value);
   //    const a = document.createElement("a");
   //    a.href = url;
   //    a.download = url.split("/").pop() || "None";
   //    document.body.appendChild(a);
   //    a.click();
   //    document.body.removeChild(a);

   //    downloadResource(url);
   await downloadFile(url, value);
};

const downloadFile = async (url: string, name: string) => {
   try {
      const response = await fetch(url, {
         method: "GET",
         credentials: "include",
         mode: "cors",
      });
      if (response.ok) {
         const blob = await response.blob();
         const urlObj = URL.createObjectURL(blob);
         const a = document.createElement("a");
         let parts = url.split(".");

         a.href = urlObj;
         a.download = name;
         document.body.appendChild(a);
         a.click();
         document.body.removeChild(a);
         URL.revokeObjectURL(urlObj);
      } else {
         console.error("Failed to download the file.");
      }
   } catch (error) {
      console.error("Error downloading the file:", error);
   }
};

// function forceDownload(blob: any, filename: any) {
//    var a = document.createElement("a");
//    a.download = filename;
//    a.href = blob;
//    // For Firefox https://stackoverflow.com/a/32226068
//    document.body.appendChild(a);
//    a.click();
//    a.remove();
// }

// // Current blob size limit is around 500MB for browsers
// function downloadResource(url: string, filename?: string) {
//    if (!filename) filename = url?.split("\\")?.pop()?.split("/").pop();
//    fetch(url, {
//       mode: "no-cors",
//    })
//       .then((response) => response.blob())
//       .then((blob) => {
//          console.log({ blob });
//          let blobUrl = window.URL.createObjectURL(blob);
//          forceDownload(blobUrl, filename);
//       })
//       .catch((e) => console.error(e));
// }
