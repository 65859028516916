import { useMutation } from "@apollo/client";
import { FormEvent, useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { SUBMIT_LOGIN } from "../../graphql/queries";
import AppContext from "../../context/AppContext";
const Field = ({ name, label, onChange, ...rest }: { name: string; label: string; type?: string; onChange?: any }) => {
   return (
      <div className='form-control flex flex-col gap-1 py-2'>
         <label htmlFor={name}>{label}</label>
         <input
            name={name}
            id={name}
            {...rest}
            className='px-4 py-2 border rounded outline-none'
            autoComplete='none'
            role='presentation'
            onInput={onChange}
         />
      </div>
   );
};
function LoginPage() {
   const [data, setData] = useState({});
   const [submitLogin] = useMutation(SUBMIT_LOGIN);
   const navigate = useNavigate();
   const appCtx = useContext(AppContext);
   useEffect(() => {
      appCtx?.changeAuth(0);
   }, []);
   const handleSubmit = (ev: FormEvent) => {
      ev.preventDefault();
      submitLogin({
         variables: {
            data,
         },
      })
         .then((res) => {
            if (res.data.submitLogin.result === 0) {
               appCtx?.changeAuth(1);
               navigate("/", {
                  replace: true,
               });
            }
         })
         .catch((err) => {
            console.error("Err: ", err);
         });
   };
   const onChange = (ev: any) => {
      let { name, value } = ev.target;
      setData({
         ...data,
         [name]: value,
      });
   };
   return (
      <div className='main-container flex justify-center items-center bg-slate-50'>
         <div className='w-80 bg-slate-100 rounded-lg'>
            <h1 className='py-4 font-bold text-lg bg-orange-300 rounded-t-lg px-7'>Safety checklist</h1>
            <form noValidate autoComplete='off' onSubmit={handleSubmit} className='px-7'>
               <Field name='email' label='Email' type='email' onChange={onChange} />
               <Field name='password' label='Password' type='password' onChange={onChange} />
               <div className='form-buttons flex gap-1 justify-end py-4'>
                  <NavLink to='/' className='border rounded px-4 py-1'>
                     Home
                  </NavLink>
                  <button type='submit' className='border rounded bg-primary-700 text-white px-4 py-1'>
                     Login
                  </button>
               </div>
            </form>
         </div>
      </div>
   );
}

export default LoginPage;
