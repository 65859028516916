import React from "react";
import { FormField } from "../../../helpers/types";
import { FieldData } from "../../../hooks/useForm";
import MultiSelect from "./MultiSelect";
import RadioSelect from "./RadioSelect";
import FileField from "./FileField";
import SetupMultiSelect from "./SetupMultiSelect";

function FormInputField({ label, options, onChange, formData, onBlur, ...rest }: FormField) {
   const inputOnChange = (ev: React.FormEvent<HTMLInputElement>) => {
      let { name, value } = ev.currentTarget;
      onChange && onChange({ name, value });
   };
   const taOnChange = (ev: React.FormEvent<HTMLTextAreaElement>) => {
      let { name, value } = ev.currentTarget;
      onChange && onChange({ name, value });
   };

   const selectOnChange = (data: FieldData) => {
      onChange && onChange(data);
   };

   const fileOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      let { name, files } = ev.currentTarget;
      onChange && onChange({ name, value: files });
   };

   let isRequired = () => {
      if (options?.isRequired) {
         return options?.isRequired(formData);
      }
      return options?.required;
   };

   let required = isRequired();

   return (
      <div className={"relative textfield border rounded w-auto" + (rest.cols ? ` col-span-2` : "")}>
         {rest.type === "textarea" ? (
            <textarea
               id={rest.name}
               className='block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
               placeholder=' '
               onChange={taOnChange}
               defaultValue={formData?.get(rest.name)}
               required={required||undefined}
               // readOnly={true}
               {...rest}
            ></textarea>
         ) : rest.type === "select" ? (
            <MultiSelect
               placeHolder={`Select ${label}`}
               choices={rest.choices || []}
               isMulti={rest.isMulti}
               name={rest.name}
               readOnly={rest.readOnly}
               onChange={selectOnChange}
               formData={formData}
               options={{ ...options, required: required}}
            />
         ) : rest.type === "select_setups" ? (
            <SetupMultiSelect
               placeHolder={`Select ${label}`}
               setupKind={rest.setupKind}
               isMulti={rest.isMulti}
               name={rest.name}
               readOnly={rest.readOnly}
               onChange={selectOnChange}
               formData={formData}
               options={{ ...options, required: required }}
            />
         ) : rest.type === "radio" ? (
            <RadioSelect
               placeHolder={`Select ${label}`}
               choices={rest.choices || []}
               isMulti={rest.isMulti}
               onChange={inputOnChange}
               formData={formData}
               options={{ ...options, required: required }}
               {...rest}
            />
         ) : rest.type === "file" ? (
            <FileField
               formData={formData}
               options={{ ...options, required: required }}
               onChange={fileOnChange}
               {...rest}
            />
         ) : (
            <input
               type={rest.type || "text"}
               id={rest.name}
               className='block px-2.5 pb-1.5 pt-3 w-full text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
               placeholder=' '
               onChange={inputOnChange}
               onBlur={(e: any) => {
                  onBlur && onBlur(e);
               }}
               defaultValue={formData?.get(rest.name)}
               required={required || undefined}
               title={options?.title || undefined}
               autoFocus={options?.autoFocus}
               pattern={
                  options?.pattern
                     ? typeof options.pattern === "function"
                        ? options.pattern(formData)
                        : options.pattern
                     : undefined
               }
               {...rest}
            />
         )}
         {label && (
            <label
               htmlFor={rest.name}
               className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1'
            >
               {required ? `${label} *` : label}
            </label>
         )}
      </div>
   );
}

export default FormInputField;
