import { useEffect, useState } from "react";
import { FormField, ReportKind, SetupKind } from "../../helpers/types";
import { useLazyQuery } from "@apollo/client";
import { GET_INSPECTION_HISTORY_REPORT, GET_RANKING_DEFECTS, GET_SUMMARIZED_REPORT } from "../../graphql/queries";
import Table from "../../components/tabular/Table";

type ReportDataType = {
   name: ReportKind;
   singular: string;
   fields: FormField[] | Function;
};

const REPORTS_DATA: ReportDataType[] = [
   {
      name: "summarized_report",
      singular: "transporter",
      fields: [
         { name: "name", label: "Transporter", options: { required: true } },
         { name: "vehicleReg", label: "Truck #", options: { required: true } },
         { name: "total", label: "Total", options: { required: true } },
         { name: "withDefects", label: "Found With Defects" },
         { name: "withoutDefects", label: "Without Defects" },
      ],
   },
   {
      name: "ranking_defects",
      singular: "defects_ranking",
      fields: (data: any[]) => {
         if (!data) return [];
         if (data.length === 0) return [];
         let fields = [{ name: "itemName", label: "Item", options: { required: true } }];
         Object.keys(data[0]).forEach((key) => {
            if (key !== "itemName") {
               let label = key;
               if (label === "totalWithDefects") label = "Total Count";
               if (label === "percentageOfTotal") label = "% Ranking";
               fields.push({ name: key, label: label, options: { required: true } });
            }
         });
         return fields;
      },
   },
   {
      name: "inspection_history",
      singular: "inspection_history",
      fields: [
         { name: "vehicleReg", label: "Truck #", options: { required: true } },
         { name: "transporter", label: "Transporter", options: { required: true } },
         { name: "countInspections", label: "Inspection Count" },
         {
            name: "lastInspection",
            label: "Last Loading Date",
            options: { required: true },
            render: ({row}: any) => {
               console.log({row})
               if (!row || !row["lastInspection"]) return "";
               return new Date(row["lastInspection"]).toLocaleDateString("en-GB");
            },
         },
         { name: "agingDays", label: "Aging(Days)" },
      ],
   },
];

export default function ReportsPage() {
   const [kind, setKind] = useState<ReportKind>("summarized_report");
   const [data, setData] = useState<Map<ReportKind, any>>(new Map());
   const setup = REPORTS_DATA.find((s) => s.name === kind);
   let setupGQL = new Map<ReportKind, { func: Function; name: string }>();
   const [getSummarizedReport, { loading: loading1 }] = useLazyQuery(GET_SUMMARIZED_REPORT, {
      fetchPolicy: "no-cache",
   });
   setupGQL.set("summarized_report", {
      name: "getSummarizedReport",
      func: getSummarizedReport,
   });
   const [getRankingDefects, { loading: loading2 }] = useLazyQuery(GET_RANKING_DEFECTS, {
      fetchPolicy: "no-cache",
   });
   setupGQL.set("ranking_defects", {
      name: "getRankingDefects",
      func: getRankingDefects,
   });
   const [getInspectionHistory] = useLazyQuery(GET_INSPECTION_HISTORY_REPORT, {
      fetchPolicy: "no-cache",
   });
   setupGQL.set("inspection_history", {
      name: "getInspectionHistory",
      func: getInspectionHistory,
   });

   useEffect(() => {
      let kind_cfg = setupGQL.get(kind);
      if (!kind_cfg || !kind_cfg === undefined) return;
      let func_name = kind_cfg.name;
      let func = kind_cfg.func;
      func().then((res: any) => {
         if (res.data) {
            if (kind === "ranking_defects") {
               let data = res.data.getRankingDefects;
               const uniqueItemNames = [...new Set(data.map((item: any) => item.itemName))];
               const uniqueTransporters = [...new Set(data.map((item: any) => item.transporter))];
               const result = uniqueItemNames.map((itemName) => {
                  const row: any = { itemName };
                  uniqueTransporters.forEach((transporter: any) => {
                     row[transporter] = 0;
                  });
                  row.totalWithDefects = 0;
                  return row;
               });
               data.forEach(({ itemName, transporter, withDefects }: any) => {
                  const row = result.find((row) => row.itemName === itemName);
                  row[transporter] += withDefects;
                  row.totalWithDefects += withDefects;
               });
               const overallTotalWithDefects = result.reduce((sum, row) => (sum += row.totalWithDefects), 0);
               result.forEach((row) => {
                  if (overallTotalWithDefects > 0) {
                     row.percentageOfTotal = ((row.totalWithDefects / overallTotalWithDefects) * 100).toFixed(0);
                  }
               });
               const finalTotalRow: any = { itemName: "Total" };
               uniqueTransporters.forEach((transporter: any) => {
                  finalTotalRow[transporter] = result.reduce((sum, row) => (sum += row[transporter]), 0);
               });
               finalTotalRow.totalWithDefects = overallTotalWithDefects;
               finalTotalRow.percentageOfTotal = 100;

               result.push(finalTotalRow);

               return setData((prev) => {
                  let updated = prev;
                  updated.set(kind, result);
                  return updated;
               });
            }
            return setData((prev) => {
               let updated = prev;
               updated.set(kind, res.data[func_name]);
               return updated;
            });
         }
      });
   }, [kind]);

   if (!setup) return null;
   const columns = typeof setup.fields === "function" ? setup.fields(data.get(kind)) : [...setup.fields];
   if (kind === "summarized_report") {
      columns.push({
         name: "ranking",
         label: "% Defects",
         render: ({ row }: any) => {
            let pct = (100 * row["withDefects"]) / row["total"];
            return (
               <label>
                  {pct.toLocaleString("en-UK", {
                     style: "decimal",
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2,
                  })}
                  %
               </label>
            );
         },
      });
   }

   return (
      <div>
         <div>
            <Table
               columns={[...columns]}
               data={data.get(kind)}
               loading={loading1 || loading2}
               title={
                  <>
                     <b>Report:</b> {kind.replaceAll("_", " ")}
                  </>
               }
               components={{
                  toolbar: () => (
                     <div className='flex gap-1'>
                        <select
                           value={kind}
                           className='px-2 py-2 rounded capitalize border'
                           onChange={(ev) => setKind(ev.target.value as ReportKind)}
                        >
                           {REPORTS_DATA.map((s, idx) => {
                              let name = s.name.replaceAll("_", " ");
                              return (
                                 <option key={idx} value={s.name}>
                                    {name}
                                 </option>
                              );
                           })}
                        </select>
                     </div>
                  ),
               }}
            />
         </div>
      </div>
   );
}
