import React, { useRef, useEffect } from "react";

function useOutsideAlerter(ref: any, cb: Function) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(event);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function OutsideAlerter({
  children,
  onOutClick,
  ...rest
}: {
  children: any;
  onOutClick: Function;
  className?: string;
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onOutClick);

  return (
    <div ref={wrapperRef} {...rest}>
      {children}
    </div>
  );
}
