import { FormEvent, useState } from "react";
import FormWrapper from "../../components/forms/FormWrapper";
import { FieldData } from "../../hooks/useForm";
import { FormField } from "../../helpers/types";
import CloseIcon from "../../components/icons/CloseIcon";
import Modal from "../../components/modal/Modal";
import { useMutation } from "@apollo/client";
import { SUBMIT_BULK_SETUP_REQUEST } from "../../graphql/queries";

export default function BulkSetups({
   fields,
   onComplete,
   title,
   name,
}: {
   onComplete: Function;
   title: string;
   name: string;
   fields: FormField[];
}) {
   const [formData, setFormData] = useState(new Map());
   const [submitSetup] = useMutation(SUBMIT_BULK_SETUP_REQUEST);
   function updateFormData({ name, value }: FieldData) {
      setFormData((prev) => {
         return new Map([...prev, [name, value]]);
      });
   }

   const handleSubmit = (ev: FormEvent) => {
      ev.preventDefault();
      let file = formData.get("file");

      // let form = {
      //    name,
      //    fields: Array.from(formData, ([key, value]) => {
      //       return {
      //          name: key,
      //          value: value,
      //          type: "text",
      //       };
      //    }),
      // };
      submitSetup({
         variables: {
            data: {
               attachment: file[0],
            },
         },
      }).then((res) => {
         onComplete();
      });
   };

   return (
      <Modal
         header={
            <div className='title flex justify-between items-center w-full'>
               <h2>Bulk Setups</h2>
               <button onClick={() => onComplete()}>
                  <CloseIcon />
               </button>
            </div>
         }
         handleClose={() => console.debug("Clicked...")}
         className='checklist-form'
      >
         <form onSubmit={handleSubmit}>
            <FormWrapper fields={fields} updateFormData={updateFormData} formData={formData} />
            <div className='flex justify-end items-center pt-3'>
               <div className='form-controls flex gap-3 justify-end py-2 text-lg'>
                  <button className='border rounded px-5 py-1' onClick={() => onComplete()} type='button'>
                     Cancel
                  </button>
                  <button type='submit' className='border rounded px-5 py-1 bg-primary text-white btn-sm'>
                     Submit
                  </button>
               </div>
            </div>
         </form>
      </Modal>
   );
}
