import { createContext } from "react";

export type UserContextType = {
   user: any;
   setUser: Function;
};

const UserContext = createContext<UserContextType>({
   user: undefined,
   setUser: () => console.error("Not implemented"),
});

export default UserContext;
