import { FormEvent, useState } from "react";
import Modal from "../../components/modal/Modal";
import FormWrapper from "../../components/forms/FormWrapper";
import useMultistepForm from "../../hooks/useMultistepForm";
import { useMutation, useQuery } from "@apollo/client";
import { FieldData } from "../../hooks/useForm";
import { GET_CHKLIST_ITEMS, SUBMIT_INSPECTION_REQUEST } from "../../graphql/queries";
import { FieldType } from "../../helpers/types";
import FormInputField from "../../components/forms/inputs/FormInputField";
import CloseIcon from "../../components/icons/CloseIcon";
import { RecordFieldsConfig } from "./config";

function ViewRecordForm({ onComplete, record }: { onComplete: Function; record: any }) {
   console.log("------------");
   let initData = new Map<string, any>();
   record.checklists.forEach((c: any) => {
      if (c.attachments.length) {
         initData = new Map<string, any>([
            ...initData,
            [`item-${c.itemId}`, c.optionValue],
            [`item-${c.itemId}-image`, c.attachments[0].filename],
            [`item-${c.itemId}-reason`, c.remarks],
         ]);
      } else {
         initData = new Map<string, any>([
            ...initData,
            [`item-${c.itemId}`, c.optionValue],
            [`item-${c.itemId}-reason`, c.remarks],
         ]);
      }
   });
   ["customer", "driver", "site", "transporter"].forEach((k: string) => {
      let val = record[k];
      if (val) initData.set(`${k}Id`, val.id);
   });
   ["vehicleReg", "vehicleType", "remarks"].forEach((k: string) => {
      let val = record[k];
      if (val) initData.set(k, val);
   });
   const { data, loading, error } = useQuery(GET_CHKLIST_ITEMS);
   const [formData, setFormData] = useState(initData);
   const [submitInspectionReq] = useMutation(SUBMIT_INSPECTION_REQUEST);
   function updateFormData({ name, value }: FieldData) {
      setFormData((prev) => {
         return new Map([...prev, [name, value]]);
      });
   }

   const getField = (i: any) => {
      return {
         name: `item-${i.id}`,
         label: i.name,
         cols: 2,
         type: "radio",
         onChange: updateFormData,
         formData: formData,
         options: {
            required: true,
         },
         readOnly: true,
         choices: [
            { label: "Compliant", value: "Compliant" },
            { label: "Partially Compliant", value: "Partial" },
            { label: "Flawed", value: "Flawed" },
         ],
         conditions: [
            {
               allow: (value: any) => ["Flawed", "Partial"].includes(value),
               present: (
                  <FormInputField
                     label='Reason'
                     name={`item-${i.id}-reason`}
                     formData={formData}
                     options={{
                        required: true,
                     }}
                     readOnly={true}
                  />
               ),
            },
            {
               allow: (value: any) => ["Flawed"].includes(value),
               present: (
                  <FormInputField
                     label='Provide image'
                     name={`item-${i.id}-image`}
                     type='file'
                     onChange={updateFormData}
                     formData={formData}
                     options={{
                        required: true,
                     }}
                     readOnly={true}
                  />
               ),
            },
         ],
      };
   };

   let cfg = RecordFieldsConfig(updateFormData, formData, true);

   const onSubmit = (ev: FormEvent) => {
      ev.preventDefault();
      if (isLastStep) {
         const arr = Array.from(formData, function (entry) {
            return {
               name: entry[0],
               value: entry[1],
               type: Array.isArray(entry[1]) ? FieldType.MULTIPLE : FieldType.SINGLE,
            };
         });

         let files = arr.filter((f) => f.name.split("-").length === 3);
         let items = arr.filter((f) => f.name.split("-").length === 2);
         let inspection: any = {};
         cfg.introFields.forEach((f) => {
            inspection[f.name] = formData.get(f.name);
         });

         const submitData = items.map((i) => {
            let parts = i.name.split("-");
            let found = files.find((f) => f.name === `${i.name}-image`);
            return {
               itemId: parseInt(parts[1]),
               optionValue: i.value,
               attachments: found ? found.value : [],
            };
         });
         submitInspectionReq({
            variables: {
               data: {
                  items: submitData,
                  remarks: "Some remarks",
                  inspection,
               },
            },
         })
            .then((res) => {
               if (res.data) {
                  onComplete();
               } else {
                  console.error("Error: ", res.errors);
               }
            })
            .catch(console.error);
      } else {
         next();
      }
   };
   let steps: any[] = [
      {
         fields: cfg.introFields,
      },
   ];
   if (data && !loading) {
      let max = 5;
      let step: any;
      data.getAllItems.forEach((i: any, idx: number) => {
         if (idx === 0 || idx % max === 0) {
            if (step) {
               steps.push(step);
            }
            step = {};
         }
         step.fields = step.fields || [];
         step.fields.push(getField(i));
      });
      if (step) {
         steps.push(step);
      }
   }
   steps.push({
      fields: cfg.finalFields,
   });
   const { numberOfSteps, currentStepIndex, step, next, back, isFirstStep, isLastStep } = useMultistepForm(
      steps.map((s, idx_) => (
         <FormWrapper key={idx_} fields={s.fields} updateFormData={updateFormData} formData={formData} />
      ))
   );

   if (loading)
      return (
         <Modal
            header={
               <div className='title flex justify-between items-center w-full'>
                  <h2>View Checklist</h2>
                  <button onClick={() => onComplete()}>
                     <CloseIcon />
                  </button>
               </div>
            }
            handleClose={() => console.debug("Clicked...")}
            className='checklist-form'
         >
            <p>Loading setups...</p>
         </Modal>
      );
   if (error) return <p>Error...</p>;
   console.log({ step });
   return (
      <Modal
         header={
            <div className='title flex justify-between items-center w-full'>
               <h2>View Checklist</h2>
               <button onClick={() => onComplete()}>
                  <CloseIcon />
               </button>
            </div>
         }
         handleClose={() => console.debug("Clicked...")}
         className='checklist-form'
      >
         <form onSubmit={onSubmit} autoComplete='nope'>
            {step}
            {step && (
               <div className='flex justify-between items-center'>
                  <p>
                     {currentStepIndex + 1} / {numberOfSteps}
                  </p>
                  <div className='form-controls flex gap-3 justify-end py-2 text-lg'>
                     {!isFirstStep && (
                        <button className='border rounded px-5 py-1' onClick={back} type='button'>
                           Back
                        </button>
                     )}
                     {!isLastStep && (
                        <button type='submit' className='border rounded px-5 py-1 bg-primary text-white btn-sm'>
                           {isLastStep ? "Submit" : "Next"}
                        </button>
                     )}
                  </div>
               </div>
            )}
         </form>
      </Modal>
   );
}

export default ViewRecordForm;
