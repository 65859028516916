import { ReactNode } from "react";

export default function Table({
   columns,
   data,
   components,
   title,
   loading,
}: {
   columns: { label?: string; name: string; render?: any }[];
   data: any[];
   title?: string | ReactNode | JSX.Element;
   components?: {
      toolbar: any;
   };
   loading?: boolean;
}) {
   return (
      <>
         {title && (
            <div className='toolbar flex justify-between px-2 py-2 items-center gap-10'>
               <h3 className='capitalize'>{title}</h3>
               {components?.toolbar ? <components.toolbar /> : null}
            </div>
         )}
         <div className='w-full p-2 table-wrap'>
            <table className='table border-collapse border border-slate-400 table-auto w-full text-left'>
               <thead className='bg-slate-100'>
                  <tr>
                     {columns.map((col, idx) => (
                        <th key={idx} className='border border-slate-300 px-2'>
                           {col.label}
                        </th>
                     ))}
                  </tr>
               </thead>
               {loading ? (
                  <p className='px-2 text-orange-400'>Loading ...</p>
               ) : !data ? (
                  <p className='px-2'>No data found</p>
               ) : (
                  <tbody>
                     {data.map((i: any, idx: number) => {
                        let total = columns.find((c) => {
                           if (c.render) return false;
                           let val = i[c.name];
                           return val === "Total";
                        });
                        return (
                           <tr key={idx} className={`row-${total ? "total" : "normal"}`}>
                              {columns.map((col, idx) => {
                                 if (idx === 0) {
                                    let val = i[col.name];
                                    return (
                                       <td key={idx} className='border border-slate-300 px-2' data-row={`${val}`}>
                                          {val}
                                       </td>
                                    );
                                 }
                                 if (!col.render) {
                                    return (
                                       <td key={idx} className='border border-slate-300 px-2'>
                                          {i[col.name]}
                                       </td>
                                    );
                                 }

                                 return (
                                    <td key={idx} className='border border-slate-300 px-2'>
                                       <col.render row={i} />
                                    </td>
                                 );
                              })}
                           </tr>
                        );
                     })}
                  </tbody>
               )}
            </table>
         </div>
      </>
   );
}
