import { ConditionalType, SelectChoice } from "../../../helpers/types";

const RadioSelect = ({
   placeHolder,
   choices,
   isMulti,
   formData,
   options,
   ...rest
}: {
   name: string;
   placeHolder?: string;
   choices: SelectChoice[];
   onChange: any;
   options?: {
      required?: boolean;
   };
   isMulti?: boolean;
   readOnly?: boolean;
   formData?: Map<string, any>;
   conditions?: ConditionalType[];
}) => {
   const ExtraPresent = () => {
      if (!rest.conditions) return null;
      return (
         <div className='flex-1 flex gap-1 items-center'>
            {rest.conditions.map((c, idx) => {
               if (!c.allow(formData?.get(rest.name))) return null;
               return (
                  <div key={idx} className='text-xs'>
                     <>{c.present}</>
                  </div>
               );
            })}
         </div>
      );
   };
   return (
      <div className='flex gap-4 items-center px-3 pt-4 pb-2 flex-wrap'>
         {choices.map((o, idx) => (
            <label htmlFor={`${rest.name}-${idx}`} className='flex gap-1 text-xs' key={idx}>
               <input
                  type='radio'
                  name={rest.name}
                  value={o.value}
                  id={`${rest.name}-${idx}`}
                  onChange={rest.onChange}
                  checked={formData?.get(rest.name) === o.value}
                  required={options?.required}
                  disabled={rest.readOnly && !(formData?.get(rest.name) === o.value)}
               />
               {o.label}
            </label>
         ))}
         <ExtraPresent />
      </div>
   );
};

export default RadioSelect;
