// http://localhost:8000/api/resources/download/CHECKLIST_8_0.pdf

export const getBaseUrl = () => {
   console.log({ env: process.env });
   if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
      return "http://localhost:8000";
   }
   return "";
};

export const getGQLUrl = () => {
   let base_url = getBaseUrl();
   console.log({ base_url });
   return `${base_url}/api/graphql`;
};

export const getDownloadUrl = (file: string) => {
   return `${getBaseUrl()}/api/resources/download/${file}`;
};
