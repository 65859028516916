import { FormField } from "../../helpers/types";

export const RecordFieldsConfig = (updateFormData: any, formData: any, readOnly?: boolean) => {
   const introFields: FormField[] = [
      {
         name: `siteId`,
         label: "Site",
         type: "select_setups",
         onChange: updateFormData,
         formData: formData,
         options: {
            required: true,
            setup: "sites",
         },
         setupKind: "sites",
         readOnly,
      },
      {
         name: `driverId`,
         label: "Driver",
         type: "select_setups",
         onChange: updateFormData,
         formData: formData,
         options: {
            required: true,
            setup: "drivers",
         },
         setupKind: "drivers",
         readOnly,
      },
      {
         name: `transporterId`,
         label: "Transporter",
         type: "select_setups",
         onChange: updateFormData,
         formData: formData,
         options: {
            isRequired: (formData: Map<string, any>) => {
               if (formData.get("customerId")) {
                  return false;
               }
               return true;
            },
            setup: "transporters",
         },
         cols: 2,
         setupKind: "transporters",
         readOnly,
      },
      {
         name: `customerId`,
         label: "Customer",
         type: "select_setups",
         onChange: updateFormData,
         formData: formData,
         options: {
            isRequired: (formData: Map<string, any>) => {
               let found = formData.has("transporterId");
               let req = !found;
               return req
            },
            setup: "customers",
         },
         cols: 2,
         setupKind: "customers",
         readOnly,
      },
      {
         name: `vehicleType`,
         label: "Vehicle Type",
         onChange: updateFormData,
         formData: formData,
         options: {
            required: true,
         },
         readOnly,
      },
      {
         name: `vehicleReg`,
         label: "Vehicle Registration Number",
         onChange: updateFormData,
         formData: formData,
         options: {
            required: true,
         },
         readOnly,
      },
   ];

   const finalFields = [
      {
         name: `remarks`,
         label: "Final Remarks",
         type: "textarea",
         cols: 2,
         onChange: updateFormData,
         formData: formData,
         options: {
            required: true,
         },
         readOnly,
      },
   ];

   return {
      introFields,
      finalFields,
   };
};
