import { NavLink } from "react-router-dom";

export default function Sidebar() {
   const menus = [
      { path: "/", label: "Dashboard" },
      { path: "/records", label: "Records" },
      { path: "/setups", label: "Setups" },
      { path: "/reports", label: "Reports" },
   ];
   return (
      <div className='w-48 sidebar flex flex-col h-full'>
         <h3 className='px-2 py-3 bg-amber-300 font-bold text-lg'>Twiga Safety</h3>
         <ul className='flex-1'>
            {menus.map((m, idx) => (
               <li key={idx}>
                  <NavLink to={m.path} className={({ isActive, isPending }) => (isActive ? "menu active" : "menu")}>
                     {m.label}
                  </NavLink>
               </li>
            ))}
         </ul>
         <ul>
            <li>
               <NavLink to='/logout' className={({ isActive, isPending }) => (isActive ? "menu active" : "menu")}>
                  Logout
               </NavLink>
            </li>
         </ul>
      </div>
   );
}
