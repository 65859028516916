export type FieldOptions = {
   required?: boolean;
   isRequired?: Function;
   pattern?: string | Function;
   title?: string;
   setup?: SetupKind;
   autoFocus?: boolean;
};

export type SelectChoice = {
   label: string;
   value: string;
};

export type ConditionalType = {
   allow: (value: any) => boolean;
   present: React.Component;
};

export type SetupKind = "transporters" | "customers" | "sites" | "drivers";
export type ReportKind = "summarized_report" | "ranking_defects"|"inspection_history";

export type FormField = {
   name: string;
   label?: string;
   cols?: number;
   rows?: number;
   type?: string;
   isMulti?: boolean;
   readOnly?: boolean;
   options?: FieldOptions;
   choices?: SelectChoice[];
   onChange?: Function;
   onBlur?: Function;
   formData?: Map<string, any>;
   conditions?: ConditionalType[];
   render?: any;
   setupKind?: SetupKind;
};

export interface GenericType {
   [key: string]: any;
}
export enum FieldType {
   SINGLE = "SINGLE",
   MULTIPLE = "MULTIPLE",
}
