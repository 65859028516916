import RecordsList from "./RecordsList";

function RecordsPage() {
   return (
      <div className=''>
         <section>
            <RecordsList />
         </section>
      </div>
   );
}

export default RecordsPage;
