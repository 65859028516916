import { gql } from "@apollo/client";

export const SUBMIT_LOGIN = gql`
   mutation submitLogin($data: LoginReq!) {
      submitLogin(data: $data) {
         result
         message
      }
   }
`;

export const SUBMIT_LOGOUT = gql`
   mutation submitLogout {
      submitLogout {
         result
         message
      }
   }
`;

export const GET_USER_PROFILE = gql`
   query {
      getUserProfile {
         result
         message
         user {
            email
            role {
               name
            }
         }
      }
   }
`;

export const SUBMIT_INSPECTION_REQUEST = gql`
   mutation SubmitInspectionReq($data: InspectionReq!) {
      submitInspection(data: $data) {
         result
         message
      }
   }
`;

export const GET_CHKLIST_ITEMS = gql`
   query {
      getAllItems {
         id
         name
         description
         type
      }
   }
`;

export const GET_INSPECTIONS = gql`
   query getAllInspections($filter: String, $page: Int, $limit: Int) {
      getAllInspections(filter: $filter, page: $page, limit: $limit) {
         totalRecords
         records {
            id
            transporter {
               id
               name
               fields {
                  name
                  value
               }
            }
            customer {
               id
               name
               fields {
                  name
                  value
               }
            }
            site {
               id
               name
               fields {
                  name
                  value
               }
            }
            vehicleType
            vehicleReg
            driver {
               id
               name
               fields {
                  name
                  value
               }
            }
            remarks
            checklists {
               id
               optionValue
               itemId
               attachments {
                  id
                  filename
               }
               remarks
            }
         }
      }
   }
`;

export const SUBMIT_SETUP_REQUEST = gql`
   mutation SubmitSetupReq($data: FormReq!) {
      submitSetup(data: $data) {
         result
         message
      }
   }
`;

export const SUBMIT_BULK_SETUP_REQUEST = gql`
   mutation SubmitBulkSetups($data: BulkSetupReq!) {
      submitBulkSetups(data: $data) {
         result
         message
      }
   }
`;

export const GET_SETUPS = gql`
   query getSetups($names: [String!]!, $filter: String, $page: Int, $limit: Int) {
      getSetups(names: $names, filter: $filter, page: $page, limit: $limit) {
         totalRecords
         records {
            id
            name
            fields {
               id
               formId
               name
               type
               value
            }
         }
      }
   }
`;

export const GET_SETUPS_MINIMAL = gql`
   query getSetups($names: [String!]!, $filter: String, $selected: [Int!]) {
      getSetups(names: $names, filter: $filter, selected: $selected) {
         totalRecords
         records {
            id
            name
            fields {
               id
               name
               value
            }
         }
      }
   }
`;

export const GET_SUMMARIZED_REPORT = gql`
   query getSummarizedReport {
      getSummarizedReport {
         name
         vehicleReg
         total
         withoutDefects
         withDefects
      }
   }
`;

export const GET_RANKING_DEFECTS = gql`
   query getRankingDefects {
      getRankingDefects {
         itemName
         transporter
         withDefects
      }
   }
`;

export const GET_INSPECTION_HISTORY_REPORT = gql`
   query getInspectionHistoryReport {
      getInspectionHistory {
         vehicleReg
         transporter
         lastInspection
         agingDays
         countInspections
      }
   }
`;
