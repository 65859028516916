import { createContext } from "react";

export type AppContextType = {
   auth: number;
   changeAuth: (newVal: number) => void;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

export default AppContext;
