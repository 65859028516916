import React from "react";
import { ChangeEventHandler, FocusEventHandler } from "react";

type SelectParams = {
   onChange: ChangeEventHandler;
   onBlur: FocusEventHandler;
   name: string;
   label: string;
   choices: { label: string; value: string }[];
   selected: string;
};

const SelectField = React.forwardRef<HTMLSelectElement, SelectParams>(
   ({ onChange, onBlur, name, label, choices, selected }, ref) => (
      <>
         <label>{label}</label>
         <select name={name} ref={ref} onChange={onChange} onBlur={onBlur} value={selected} className="p-1 rounded">
            {choices.map((c) => (
               <option value={c.value}>{c.label}</option>
            ))}
         </select>
      </>
   )
);

export default SelectField;
